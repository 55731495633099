body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#container {
	 margin-left: inherit;
     top: 0;
    }
#layer1{
    /* border: 1px solid #666; */
    position: absolute;
    background-repeat: no-repeat;
    /* width: 1600px;
    height: 900px;; */
}
.layer_bckg1 {
    background-image: url("/Business Model Canvas.jpg");
    background-size: contain;
}
#canvas_foreground, #backgroundimage_canvas{
    position: absolute;
}
.event_container {
    display: flex;
    position: absolute;
    top: 0;
    /* left: 0; */
    /* position: inherit; */
}
.layer1{
    /* border: 1px solid #666; */
    position: absolute;
    background-repeat: no-repeat;
    background-color: white;
    /* width: 1600px;
    height: 900px; */
}
.canvas_foreground{
    position: absolute;
    /* text-transform: uppercase; */
}
.event_wall {
    background-image: url("/Brickwall_background.jpg");
    /* background-repeat: repeat; */
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
    position: absolute;
    top:0px;
    left:0px;
    width: 100%;
    height: 100%;
}

.event_wall_no_bkg .layer1{
    border: 1px solid #666;
}